import React from "react";
import { Link } from "gatsby";
import get from "lodash/get";
import Seo from "../components/seo";
import Layout from "../components/layout";

class MessageSentPage extends React.Component {
  render() {
    const siteTitle = get(this, "props.data.site.siteMetadata.title");

    return (
      <Layout location={this.props.location}>
        <Seo title={`Message Successfully Sent | ${siteTitle}`} />
        <section className="section is-medium">
          <div className="container has-text-centered content">
            <h1 className="title">Thank you for sending us a message!</h1>

            <p className="has-text-left mb-5">
              All messages are immediately routed to our team. We work hard to
              respond as soon as possible. If you don't hear from us within 4
              hours, please give us a call at 210-223-2627 or email us at
              attorney@mcfirm.com. We look forward to hearing more about your
              case. -Martin, Cukjati &amp; Tom
            </p>
            <p>
              <Link to="/" className="button mr-2">
                Home
              </Link>
              <Link to="/about/" className="button">
                Contact
              </Link>
            </p>
          </div>
        </section>
      </Layout>
    );
  }
}

export default MessageSentPage;
